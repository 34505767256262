<template>
  <v-tabs
    v-model="tab"
    background-color="transparent"
    class="crop-tabs"
    color="blue"
    grow
    @change="cropChanged"
  >
    <v-tab v-for="(crop, index) in enabledsCrops" :key="crop.id">
      <span class="title-tab">
        <img
          v-if="tab === index"
          loading="lazy"
          max-width="15px"
          :alt="cropIcon(crop.name, '-green').alt"
          :src="cropIcon(crop.name, '-green').src"
        />
        <img
          v-else
          loading="lazy"
          max-width="15px"
          :alt="cropIcon(crop.name).alt"
          :src="cropIcon(crop.name).src"
        />
      </span>
      {{ $t(`crop.${crop.name.toLowerCase()}`) }}
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Croptabs',

  props: {
    disableCrops: {
      default: () => [],
      type: Array,
    },
  },

  data() {
    return {
      tab: 0,
    }
  },

  mounted() {
    this.tab = this.currentCrop.id - 1
  },

  computed: {
    ...mapGetters('crops', ['currentCrop']),
    ...mapGetters('seasonsCrops', ['enabledCrops']),
    enabledsCrops() {
      return this.enabledCrops.filter((crop) => !this.isDisabled(crop.name))
    },
  },

  methods: {
    ...mapActions('crops', ['setCurrentCrop']),
    isDisabled(crop) {
      return this.disableCrops.includes(crop)
    },
    cropChanged(cropIndex) {
      const cropId = this.enabledCrops[cropIndex].id

      this.setCurrentCrop(cropId)
    },
    cropIcon(cropName, active = null) {
      return {
        alt: cropName,
        src: require(`@/assets/icons/ic-${cropName.toLowerCase()}${
          active ?? ''
        }.svg`),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.crop-tabs {
  border: 1px solid #e6e9e6;
  border-radius: 8px 8px 0px 0px;

  .title-tab {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding-right: 5px;
  }
}
.icon-active {
  position: relative;
}
.icon-active:after {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #39af49;
  mix-blend-mode: multiply;
}
::v-deep .v-responsive__content {
  width: 15px !important;
}
::v-deep .v-tab--active {
  color: #39af49 !important;
  font-weight: 800;
  font-size: 14px;
}
::v-deep .v-tabs-bar__content {
  color: #39af49 !important;
}
::v-deep .v-tabs-slider-wrapper {
  height: 3px !important;
}
</style>
